import React, { useCallback, useEffect, useState } from "react";
import { apiReleaseNoteGet, apiReleaseNotesGetList } from "../../api/apiReleaseNotes";
import PageTitle from "../common/PageTitle";
import Loader from "../common/Loader";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import moment from "moment";
import { useContext } from "react";
import { UserPreferencesContext } from "../../context/userPreferences";
import { CurrentSiteIdGet } from "../../api/userSettings";

const ReleaseNotes = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { savePreference, prefsObj, prefsLoaded } = useContext(UserPreferencesContext);

	const LoadData = useCallback(async () => {
		setLoading(true);
		const siteId = CurrentSiteIdGet();
		const result = await apiReleaseNotesGetList(siteId);
		setData(result);
		setLoading(false);
	}, []);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	useEffect(() => {
		if (!prefsLoaded) {
			return;
		}

		if (data.length === 0) {
			return;
		}

		const date = data.reduce((p, c) => (p.date > c.date ? p : c), "")?.date;

		if (date && date > (prefsObj.LastReleaseNote || "")) {
			savePreference("Last_ReleaseNote", date);
		}
	}, [data, savePreference, prefsObj, prefsLoaded]);

	const columns = [{ field: "date", title: local.TS_Date, defaultSort: "desc", render: (rowData) => <div>{moment(rowData.date).format("DD MMM YYYY")}</div> }, { field: "name", title: local.TS_Name }];

	function download(filename, text) {
		var element = document.createElement("a");
		element.setAttribute("href", "data:application/octet-stream;charset=utf-16le;base64," + text);
		element.setAttribute("download", filename);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	const rowClick = async (_e, rowData) => {
		const siteId = CurrentSiteIdGet();
		const file = await apiReleaseNoteGet(siteId, rowData.filename);
		download(rowData.filename, file);
	};

	return (
		<>
			<PageTitle title={local.TF_Release_Notes} />
			{loading && <Loader />}
			{!loading && (
				<>
					<MaterialTable onRowClick={rowClick} columns={columns} data={data} title="" icons={tableIcons} options={{ headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
				</>
			)}
		</>
	);
};

export default ReleaseNotes;
