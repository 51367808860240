import axios from "axios";

export const apiReleaseNotesGetList = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/ReleaseNotes",
			params: { siteId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiReleaseNoteGet = async (siteId, filename) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/ReleaseNote",
			params: { siteId, filename },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};
