import React, { useState, useEffect, useRef } from "react";
import { CurrentUserDisplayName, AccessSite, CurrentUserSites } from "../../api/auth";
import { Button, Modal, ModalBody, Form, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageTitle from "../common/PageTitle";
import local from "../../localization/strings";
import DistributorSiteList from "./DistributorSiteList";
import SiteSettingsForm from "./SiteSettingsForm";
import { siteStats } from "../../api/site";
import { CurrentSiteIdSet } from "../../api/userSettings";
import DistributorSiteImages from "./DistributorSiteImages";
import { alarmTeamEmergency } from "../../api/alarmTeams";

const Distributor = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [sites, setSites] = useState(undefined);
	const [showSiteSettings, setShowSiteSettings] = useState(false);
	const [showSiteSettingsId, setShowSiteSettingsId] = useState(undefined);
	const [accessModalOpen, setAccessModalOpen] = useState(false);
	const [accessSiteId, setAccessSiteId] = useState(undefined);
	const [accessCodeError, setAccessCodeError] = useState(false);
	const accessCodeRef = useRef(null);
	const [asAt, setAsAt] = useState(undefined);

	const accessSite = (id) => {
		if (CurrentUserSites().includes(id)) {
			CurrentSiteIdSet(id);
			window.location.href = "/";
			return;
		}
		setAccessCodeError(false);
		setAccessSiteId(id);
		setAccessModalOpen(true);
	};

	const cancelAccessSite = () => {
		setAccessCodeError(false);
		setAccessSiteId(null);
		setAccessModalOpen(false);
	};

	const accessSiteHandler = async () => {
		var result = await AccessSite(accessSiteId, accessCodeRef.current.value);

		if (result) {
			if (CurrentUserSites().includes(accessSiteId)) {
				CurrentSiteIdSet(accessSiteId);
				window.location.href = "/";
				return;
			}
		}

		setAccessCodeError(true);
	};

	async function loadData() {
		setSites(await siteStats());
		setAsAt(new Date());
	}

	useEffect(() => {
		loadData();
	}, []);

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const tabNavItem = (index, title) => {
		return (
			<NavItem>
				<NavLink
					className={activeTab === index ? "active" : ""}
					onClick={() => {
						toggle(index);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	const activateEmergencyAlarms = async (id) => {
		await alarmTeamEmergency(id, true);
		await loadData();
	};
	const deactivateEmergencyAlarms = async (id) => {
		await alarmTeamEmergency(id, false);
		await loadData();
	};

	const siteSettings = (id) => {
		setShowSiteSettingsId(id);
		setShowSiteSettings(true);
	};

	const cancelSiteSettings = () => {
		setShowSiteSettings(false);
		setShowSiteSettingsId(undefined);
	};

	return (
		<>
			<Modal isOpen={accessModalOpen} toggle={() => setAccessModalOpen(!accessModalOpen)} centered backdrop="static">
				<ModalBody>
					<Form>
						<div className="form-group">
							<Label for="inputCode">{local.TF_Access_Code}</Label>
							<Input innerRef={accessCodeRef} type="text" className={`form-control ${accessCodeError && "border border-danger"}`} id="inputCode" placeholder={local.TF_Access_Code_Placeholder} />
							{accessCodeError && <div className="text-danger">{local.TF_Access_Code_Error}</div>}
							<small>{local.TF_Access_Code_Find}</small>
						</div>
						<Button size="sm" color="primary" onClick={() => accessSiteHandler()}>
							{local.TF_Access_Site}
						</Button>
						<Button size="sm" color="secondary" onClick={() => cancelAccessSite()} className="ml-2">
							{local.TS_Cancel}
						</Button>
					</Form>
				</ModalBody>
			</Modal>

			<PageTitle title={CurrentUserDisplayName()} />
			<Nav tabs>
				{tabNavItem(1, local.TF_View_Sites)}
				{tabNavItem(2, local.TF_Site_Details)}
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={1}>{asAt && <DistributorSiteImages sites={sites} accessSite={accessSite} when={asAt} />}</TabPane>
				<TabPane tabId={2}>{showSiteSettings ? <SiteSettingsForm id={showSiteSettingsId} cancelSiteSettings={cancelSiteSettings} reload={loadData} /> : <DistributorSiteList sites={sites} deactivateEmergencyAlarms={deactivateEmergencyAlarms} activateEmergencyAlarms={activateEmergencyAlarms} accessSite={accessSite} siteSettings={siteSettings} />}</TabPane>
			</TabContent>
		</>
	);
};

export default Distributor;
