import { faBell, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import moment from "moment";
import "moment/min/locales";
import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import SMSUsage from "../admin-area/SmsUsage";

const DistributorSiteList = ({ sites, accessSite, siteSettings, activateEmergencyAlarms, deactivateEmergencyAlarms }) => {
	const columns = [
		{
			title: local.TF_Customer,
			field: "displayName",
			defaultSort: "asc",
			render: (rowData) => (
				<div className="mx-auto">
					<div className="d-flex flex-nowrap">
						{rowData.darcaHubOffline ? <FontAwesomeIcon className="danger-icon fs-1 mr-2" icon={faExclamationCircle} title={local.TF_Darca_hub_offline} /> : <FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faBell} />}
						{rowData.emergencyAlarmCountOn > 0 ? <FontAwesomeIcon className="danger-icon fs-1 mr-2" icon={faBell} title={local.TS_Emergency_Alarms_Enabled} /> : <FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faExclamationCircle} />}
						<span>{rowData.displayName}</span>
					</div>
				</div>
			),
		},
		{ title: local.TF_ProductType, field: "productType" },
		{
			title: local.TF_ContractStart,
			field: "contractStartDate",
			render: (rowData) => <div>{rowData.contractStartDate ? moment(rowData.contractStartDate).format("DD MMM YYYY") : ""}</div>,
		},
		{ title: local.TF_NumberOfUsers, field: "userCount" },
		{ title: local.TF_NumberOfTransmitters, field: "transmitterCount" },
		{ title: local.TF_NumberOfTransmitterChannels, field: "transmitterChannelCount", render: (rowData) => <div title={local.TF_SiteList_TXChannels_Tooltip}> {rowData.transmitterChannelDisplay}</div> },
		{ title: local.TF_SMS_Usage, field: "smsUsage", render: (rowData) => <SMSUsage used={rowData.annualSmsSegmentsUsed} limit={rowData.annualSmsSegmentLimit} siteId={rowData.id} /> },
		{
			title: local.TS_Actions,
			render: (rowData) => (
				<UncontrolledDropdown style={{ position: "static" }}>
					<DropdownToggle tag="div" className="pin-button">
						<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
					</DropdownToggle>
					<DropdownMenu className="border">
						<DropdownItem onClick={() => accessSite(rowData.id)}>{local.TF_Access_Site}</DropdownItem>
						<DropdownItem onClick={() => siteSettings(rowData.id)}>{local.TF_Change_Settings}</DropdownItem>
						{rowData.emergencyAlarmCountOff > 0 && (
							<DropdownItem onClick={() => activateEmergencyAlarms(rowData.id)}>
								{local.TS_Emergency_Alarms_Enable}
								<div className="fs--2">({local.TS_Emergency_Alarms_Warn})</div>
							</DropdownItem>
						)}
						{rowData.emergencyAlarmCountOn > 0 && <DropdownItem onClick={() => deactivateEmergencyAlarms(rowData.id)}>{local.TS_Emergency_Alarms_Disable}</DropdownItem>}
					</DropdownMenu>
				</UncontrolledDropdown>
			),
		},
	];

	return (
		<div className="MuiTable text-center">
			<MaterialTable
				columns={columns}
				data={sites}
				title=""
				icons={tableIcons}
				options={{
					padding: "dense",
					sorting: true,
					exportButton: true,
					exportAllData: true,
					exportFileName: "Site List",
					paging: true,
					pageSize: 20,
					pageSizeOptions: [20, 50, 100],
					emptyRowsWhenPaging: false,
					headerStyle: {
						backgroundColor: "#fff",
						color: "#000",
					},
					rowStyle: {
						textAlign: "center",
					},
				}}
			/>
		</div>
	);
};

export default DistributorSiteList;
